import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { LMSG_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import ContentSection from '@/components/Portfolio/ContentSection';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import Testimonial from '@/components/Testimonial';
import NextProject from '@/components/Portfolio/NextProject';
import DoublePictures from '@/components/Portfolio/DoublePictures';

import testimonial from '@/referentials/testimonials/setec';

export default function Setec({ data }) {
  const { t } = useTranslation();

  const tags = ['ux', 'training', 'designThinking', 'proto'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverSetec')} />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
      <SoloPicture source={data.figjam} alt={t('figjam')} />
      <ContentSection title={t('paragraph2Title')} content={t('paragraph2')} />
      <DoublePictures
        pictures={[
          {
            source: data.leftImage,
            alt: 'Notion',
          },
          {
            source: data.rightImage,
            alt: 'UX/UI formation',
          },
        ]}
      />
      <ContentSection title={t('paragraph3Title')} content={t('paragraph3')} />

      <Testimonial testimonial={testimonial()} />

      <NextProject title={t('nextProject')} to={LMSG_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["setec", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/setec/screens.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    figjam: file(relativePath: { eq: "projects/setec/figjam.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    leftImage: file(relativePath: { eq: "projects/setec/notion.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    rightImage: file(relativePath: { eq: "projects/setec/presentation.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
