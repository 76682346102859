import React from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

interface DoublePicturesProps {
  pictures: {
    source: ImageDataLike;
    alt: string;
  }[];
}

const DoublePictures: React.FC<DoublePicturesProps> = ({ pictures }) => (
  <div className="flex flex-row justify-between my-12 gap-2 lg:my-8 lg:gap-4">
    {pictures.map((elem, index) => (
      <GatsbyImage
        key={index}
        image={getImage(elem.source)}
        alt={elem.alt}
        className="w-full rounded-main"
      />
    ))}
  </div>
);

export default DoublePictures;
